import React, { MutableRefObject, useRef } from 'react';
import { GoogleMap, useLoadScript, PolygonF, Libraries } from '@react-google-maps/api';

const App: React.FC = () => {
  const mapContainerStyle = {
    width: '60vw',
    height: '90vh',
  };

  const center = {
    lat: 32.875,
    lng: -97.33697406078338,
  };

  const libraries: MutableRefObject<Libraries> = useRef(['places']);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY || 'AIzaSyBQK0gQdXO5jfZThhCFND45UGDVfwYCLl4',
    libraries:libraries.current,
  });

  const polygonPathDiamond=[
    { lat: 33.35, lng: -97.65 },
    { lat: 33.15, lng: -96.9 },
    { lat: 32.4, lng: -97. },
    { lat: 32.5, lng: -97.6 },
  ]

  if (loadError) {
    return <div>Error loading maps</div>;
  }

  if (!isLoaded) {
    return <div>Loading maps</div>;
  }

  return (
    <div>
      <h4 className='map-header'>Service Area</h4>
    <GoogleMap
      mapContainerStyle={mapContainerStyle}
      zoom={10}
      center={center}
    ><PolygonF
      path={polygonPathDiamond}
      options={{
        fillColor: 'cornflowerblue',
        fillOpacity: 0.4,
        strokeColor: '#2c8080',
        strokeOpacity: 1,
        strokeWeight: 2,
        zIndex:-1
      }}
    />
    </GoogleMap>
    </div>
  );
};

export default App;
